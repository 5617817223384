<template>
  <section id="hero"
           class=" gif-background-section h-section flex flex-col justify-center items-center text-off-white">
    <img class="lg:hidden sm:2/3 md:w-1/2" src="../assets/images/logo-full.png" alt="logo">
    <div
        class="lg:w-2/3 flex flex-col items-center justify-center bg-off-black bg-opacity-80 lg:bg-transparent">
      <img class="hidden lg:block w-2/3" src="../assets/images/coyaluHeader.png" alt="logo">
      <h3 class="text-2xl md:text-4xl py-6 px-6 lg:w-2/3 lg:bg-off-black lg:bg-opacity-80 ">
        Reimagining Serenity and Wellness
      </h3>
    </div>
  </section>
  <div class="bg-black py-[100px] md:py-navbar flex justify-center wall-bg md:border-y-4 border-bronze-center">
    <div
        class="bg-gray bg-opacity-50 border-y-8 border-bronze-center w-full md:w-2/3">
      <p class="text-off-white text-balance lg:text-left">
        The name COYALU is inspired by the Arawak word “Coyaba” meaning Place of Rest and the Luganda word “Gulu” meaning Heaven.
        This fusion symbolizes our commitment to creating a virtual space where culture, wellness, and technology merge to support
        mental health and innovation in a culturally resonant way.
      </p>
      <p class="text-off-white text-balance lg:text-left">
        <strong>Vision:</strong> To democratize wellness and self-care tools, making them accessible and culturally resonant,
        thereby supporting mental health both at home and at work.
      </p>
      <p class="text-off-white text-balance lg:text-left  ">
        <strong>Mission:</strong> To create extended reality (XR) experiences that not only expand capacities for self-care and
        mental wellness but
        also inspire users through immersive cultural narratives.
      </p>
    </div>
  </div>
  <ContactSection/>
</template>

<script>
import ContactSection from '@/sections/ContactSection.vue';


export default {
  name: 'HomeView',
  components: {
    ContactSection,
  },
};
</script>


<style scoped>
.gif-background-section {
  background-image: url('@/assets/videos/fantasyForestBackground.gif');
  background-size: cover; /* Cover the entire section */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
}

p {
  @apply text-xl md:text-3xl xl:text-4xl py-6 px-6
}
</style>