<template>
  <div id="contact" class="flex flex-col items-center lg:w-full my-10">
    <h3 class="text-5xl md:text-6xl lg:text-5xl text-metallic px-10">Get in Touch</h3>
    <div id="mc_embed_shell" class="w-full md:w-2/3">
      <div id="mc_embed_signup" class="w-full">
        <form
            action="https://gmail.us18.list-manage.com/subscribe/post?u=49588118148b431ff76bc19ea&amp;id=5c51402729&amp;f_id=00d341e4f0"
            method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" style="margin-left: 0"
            class="validate p-10 w-full bg-off-black md:bg-dark-gray" target="_blank">
          <div id="mc_embed_signup_scroll" class="flex flex-col items-center justify-center"><p
              class="text-4xl text-off-white  text-center my-5 lg:my-0">Send us
            a message</p>
            <div class="indicates-required text-2xl text-off-white my-5"><p class=" text-center lg:text-right"><span
                class="asterisk">*</span>
              indicates
              required</p>
            </div>
            <div class="mc-field-group my-5 text-2xl text-off-white text-center lg:text-left"><label for="mce-EMAIL">Email Address
              <span
                  class="asterisk">*</span></label><input
                type="email" name="EMAIL" class="required email text-off-black" id="mce-EMAIL" required="" value=""><span
                id="mce-EMAIL-HELPERTEXT" class="helper_text" style="background-color: #2a2a2a"></span></div>
            <div class="mc-field-group my-5 text-2xl text-off-white text-center lg:text-left"><label for="mce-FNAME">First
              Name
              <span
                  class="asterisk">*</span></label><input
                type="text" name="FNAME" class="required text text-off-black" id="mce-FNAME" value="" required=""></div>
            <div class="mc-field-group my-5 text-2xl text-off-white text-center lg:text-left"><label for="mce-LNAME">Last
              Name </label><input
                type="text"
                name="LNAME"
                class=" text text-off-black"
                id="mce-LNAME"
                value=""></div>
            <div class="mc-field-group my-5 text-2xl text-off-white text-center lg:text-left">
              <label for="mce-MMERGE6">Message <span class="asterisk">*</span></label>
              <textarea type="text"
                        rows="6"
                        name="MMERGE6"
                        class="required text text-off-black"
                        id="mce-MMERGE6"
                        value=""
                        required=""></textarea>
            </div>
            <div hidden=""><input type="hidden" name="tags" value="1074523"></div>
            <div id="mce-responses" class="clear foot">
              <div class="response" id="mce-error-response" style="display: none;"></div>
              <div class="response" id="mce-success-response" style="display: none;"></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px;">
              /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
              <input type="text" name="b_49588118148b431ff76bc19ea_5c51402729" tabindex="-1" value="">
            </div>
            <div class="optionalParent">
              <div class="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Submit">
                <p style="margin: 0px auto;" class="text-2xl"><a href="http://eepurl.com/iLt1QY"
                                                                 title="Mailchimp - email marketing made easy and fun"><span
                    style="display: inline-block; background-color: transparent; border-radius: 4px;"></span></a>
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>

<style scoped>
#mc-embedded-subscribe {
  @apply flex items-center justify-center h-16 w-40 text-2xl hover:text-gold my-10 lg:my-3 bg-gold text-black
}

#mc_embed_signup .foot {
  @apply flex flex-col items-center justify-center lg:items-start
}
</style>
<script setup>
</script>