<template>
  <div id="contact" class="bg-black border-t-4 border-bronze-center flex flex-col items-center py-20 wall-bg">
    <p class="w-full md:w-2/3 bg-gray bg-opacity-50 border-y-8 border-bronze-center text-2xl py-6 px-6 text-balance text-off-white">
      This project was made with the support of the OYA Black Arts Coalition through the OYA Scale Up Immersive Media Lab and in
      partnership with the Canadian Film Centre, produced with the generous support of ACTRA Toronto and funded by the Government
      of Canada through the Federal Economic Development Agency for Southern Ontario.
    </p>
    <p class="text-5xl text-metallic mt-20 ">Follow us</p>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-10 items-center justify-center p-4 lg:my-10">
      <a class="items-center justify-center"
         v-for="(image,index) in socials"
         :key="index"
         :href="image.link"
         target="_blank">
        <img class="w-16" :src="image.image" :alt="image.name">
      </a>
    </div>
  </div>
</template>

<script>

import facebookSquare from '@/assets/icons/facebook-square.png';
import facebookGroup from '@/assets/icons/facebook-group.png';
import instagram from '@/assets/icons/instagram.svg';


export default {
  data() {
    return {
      socials: [
        { image: facebookSquare, link: 'https://www.facebook.com/profile.php?id=61556632508550' },
        {
          image: facebookGroup,
          link: 'https://www.facebook.com/groups/328740583056319/people/?should_open_welcome_member_composer=1',
        },
        { image: instagram, link: 'https://www.instagram.com/coyalu/' },
      ],
    };
  },
};
</script>